import { Button, Input } from '@mui/material';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import InputComponent from '../../components/InputComponent';
import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';
import { toast } from 'react-toastify';
import { context } from '../../contexts';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Contador from '../../assets/PortalContador.png'
export default function Login() {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [visible, setVisible] = useState(false)
    const { Login, loading } = useContext(context)

    function RunLogin(e) {
        e.preventDefault()
        if (user !== '' && password !== '') {
            Login(user, password)
        } else {
            toast.warn('Preencha os campos vazios')
        }
    }

    return (


        <div className='overflow-y-hidden'>

            {
                loading ?

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}


                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> : ''
            }
            <div className="flex w-full h-full relative max-md:flex-col bg-[#d9d8d6]" >



                {/**imagem */}
                <div className="  w-[50%] h-[100vh] max-md:hidden">
                
                <img src={Contador}/>
                    


                </div>

                {/**form */}
                <div className="w-[50%] mt-24 h-[100%] max-md:w-full ">
                    <div className='flex flex-col justify-center items-center' >
                        <h2 className="text-center font-semibold text-xl text-black ">Login</h2>
                        <form className='flex flex-col items-center my-16 gap-6'>

                            <InputComponent
                                placeholder={'Usuário'}
                                value={user}
                                Onchange={(e) => setUser(e.target.value)}
                                type='text'
                            />
                            <div className='relative'>

                                <InputComponent
                                    placeholder={'Senha'}
                                    value={password}
                                    Onchange={(e) => setPassword(e.target.value)}
                                    type={visible ? 'text' : 'password'}

                                />
                                <div className='cursor-pointer absolute right-4 bottom-2'>
                                    {visible ?
                                        <Eye
                                            size={20}
                                            color='grey'
                                            onClick={() => setVisible(false)}

                                        /> :

                                        <EyeOff
                                            size={20}
                                            color='grey'
                                            onClick={() => setVisible(true)}
                                        />

                                    }

                                </div>

                            </div>

                            <button className='text-white bg-orange-500 rounded-md py-2 px-6 hover:bg-orange-300 duration-200 w-[20rem]'
                                onClick={RunLogin}
                            >
                                Entrar
                            </button>


                            <Link to={'/recoverPassword'}>
                                <p className='text-sm cursor-pointer hover:text-orange-500'>Esqueci minha senha</p>
                            </Link>

                        </form>
                    </div>


                </div>
            </div>


        </div>
    )
}