import InputComponent from "../../components/InputComponent"
import { useState } from 'react';
import { CheckIcon } from "lucide-react";
import CheckAnimate from '.././../assets/check.json'
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import { CircularProgress, Popover } from "@mui/material";
import { CircleAlert } from 'lucide-react';
import axios from "axios";
import api from './../../services/api';
import { toast } from 'react-toastify';
import Contador from '../../assets/PortalContador.png'
export default function RecoverPassword() {
    const [email, setEmail] = useState('')
    const [concluded, setConcluded] = useState(false)
    const [loading, setLoading] = useState(false)
    //se o usuario tentar clicar no botao sem preencher o email, ativa o open

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    async function Send() {

        setLoading(true)
        await axios.put(`${api}usuario/recuperarsenha/${email}`)
            .then((res) => {
                setLoading(false)
                if (res.data === 'Não existe email cadastrado!') {
                    toast.warn('Este email não está cadastrado')
                } else {
                    setConcluded(true)

                }


            }).catch((error) => {
                toast.error('Erro ao enviar email, tente novamente mais tarde!')
                console.log(error)

            })




    }
    return (
        <div className="flex w-full h-full relative overflow-y-hidden bg-[#d9d8d6]" >
            {/**imagem */}
            <div className=" justify-center  w-[50%] h-[100vh] max-md:hidden ">
              
              
                <img src={Contador}/>

            


            </div>

            {/**form */}
            <div className="w-[50%] mt-32 h-[100%] max-md:w-full   ">
                <div className='flex flex-col justify-center items-center ' >
                    <h2 className="text-center font-semibold text-xl text-black ">Esqueceu sua senha?</h2>
                    <span className='text-xs'>Informe o email cadastrado que em breve enviaremos a sua senha de acesso. </span>
                    <div className='flex flex-col items-center my-16 gap-6'>
                        {
                            concluded === false ?
                                <div className="flex flex-col gap-6 ">
                                    <div className="relative">
                                        <InputComponent
                                            placeholder={'Digite seu email'}
                                            value={email}
                                            Onchange={(e) => setEmail(e.target.value)}
                                            type='email'
                                        />
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <div className="flex gap-1 items-center mx-2 my-1">
                                                <CircleAlert className="text-yellow-500" />
                                                <p className="text-sm">Preencha este campo</p>


                                            </div>



                                        </Popover>

                                    </div>


                                    <button className='text-white bg-orange-500 rounded-md py-2 px-6 hover:bg-orange-300 duration-200 w-[20rem]'
                                        onClick={(e) => {
                                            if (email === '') {
                                                handleClick(e)
                                            } else {
                                                Send()
                                            }
                                        }}
                                    >
                                        Enviar
                                    </button>

                                </div> :

                                <div className="flex flex-col items-center">
                                    <Lottie animationData={CheckAnimate}
                                        loop={false}
                                        autoplay
                                        className='w-48'
                                    />
                                    <span className="font-semibold text-xl">Email Enviado!</span>
                                    <Link to='/'>
                                        <button className='text-white bg-orange-500 rounded-md py-2 px-6 hover:bg-orange-300 duration-200 w-[20rem] mt-6'

                                        >
                                            {
                                                loading ?
                                                    <CircularProgress size={20} color='inherit' /> :
                                                    'Voltar'
                                            }

                                        </button>
                                    </Link>

                                </div>
                        }



                    </div>
                </div>



            </div>

        </div>
    )
}